.author-overlay__wrapper {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.author-overlay {
  position: sticky;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 80%;
  height: fit-content;
  width: fit-content;
  bottom: 20px;
}

.author-overlay__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.author-overlay__header > h1 {
  color: rgb(254, 159, 43);
  font-size: 2rem;
}

.author-overlay__header > button {
  background-color: rgb(254, 159, 43);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.author-overlay__body {
  display: flex;
  justify-content: center;
}

.author-overlay__body > img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.author-overlay__body > div {
  flex: 1;
  margin-left: 20px;
}

.author-overlay__body > div > h2 {
  color: rgb(254, 159, 43);
  font-size: 1.5rem;
}

.author-overlay__body > div > p {
  color: #6a5d5d;
  font-weight: 400;
  font-size: 1rem;
}

.author-overlay__body > div > a {
  color: rgb(254, 159, 43);
  text-decoration: none;
}

.author-overlay__body > div > a:hover {
  text-decoration: underline;
}

.author-overlay__body > div > a > img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.author-overlay__body > div > a > span > img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.author-overlay__link {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.author-overlay__linkIcon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.author-overlay__linkText {
  font-size: 0.8rem;
}
